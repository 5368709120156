


























import { Component, Vue, Prop } from 'vue-property-decorator';

import ContentBlocks from '~/patterns/organisms/_collections/content-blocks/content-blocks.vue';
import PageLayout from '~/patterns/organisms/page-layout/page-layout.vue';
import GhHero from '~/patterns/organisms/gh-hero/gh-hero.vue';

import ContentfulPageApi from '~/api/contentful/page';
import { PageType } from '~/types/Page';
import { ContentBlockEntry } from '~/types/ContentBlocks';
import { MunicipalityBannerEntry } from '~/types/MunicipalityBanner';

@Component({
    components: { PageLayout, ContentBlocks, GhHero }
})
class ErrorLayout extends Vue {
    @Prop() readonly error!: { message: string; statusCode: number };

    pageNotFoundEntry: PageType | null = null;

    get statusCode(): number {
        return (this.error && this.error.statusCode) || 500;
    }

    get message() {
        return this.error.message || 'Er ging iets mis';
    }

    get pageBlocks(): ContentBlockEntry[] | null {
        if (this.pageNotFoundEntry && this.pageNotFoundEntry.fields) {
            return this.pageNotFoundEntry.fields.structure ? this.pageNotFoundEntry.fields.structure : null;
        }

        return null;
    }

    get hero(): MunicipalityBannerEntry | null {
        return this.pageNotFoundEntry && this.pageNotFoundEntry.fields ? this.pageNotFoundEntry.fields.hero : null;
    }

    fetchPageNotFoundContent(): Promise<PageType | null> {
        return new ContentfulPageApi()
            .getBySlug('404')
            .then(entries => {
                if (entries.total > 0) {
                    this.pageNotFoundEntry = entries.items[0];
                    return entries.items[0];
                }
                this.pageNotFoundEntry = null;
                return null;
            })
            .catch(() => {
                this.pageNotFoundEntry = null;
                return null;
            });
    }

    beforeMount() {
        if (this.statusCode === 404) {
            this.fetchPageNotFoundContent();
        }
    }
}

export default ErrorLayout;
