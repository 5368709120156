import config from '../config/config';
import { MunicipalityType } from '~/types/Municipality';

/**
 * Create a filter for Municipality OR nationwide entries
 * @param municipality
 * @param filterField which field to perform the filtering on
 */
export function filterByMunicipalityOrNation(
    municipality: MunicipalityType,
    filterField: string = 'fields.availability'
): object {
    const filter = {};

    if (municipality && municipality.sys) {
        // If a municipality filter is set, also include nationwide financial benefits.
        filter[`${filterField}.sys.id[in]`] = [municipality.sys.id, config.contentful.nationId].join(',');
    }
    return filter;
}

/**
 * Create a filter for Municipality, Province or Country, based on Province and Country linked to Municipality.
 * Return a filter for nationwide entries when no Municipality is present.
 * @param municipality
 * @param filterField which field to perform the filtering on
 */
export function filterByMunicipalityProvinceCountry(
    municipality?: MunicipalityType,
    filterField: string = 'fields.availability'
): object {
    const filter = {};
    if (municipality) {
        const locations = [municipality.sys.id];

        // See whether the municipality has a province + country linked to it.
        if (municipality.fields.province) {
            locations.push(municipality.fields.province.sys.id);
            if (municipality.fields.province.fields.country) {
                locations.push(municipality.fields.province.fields.country.sys.id);
            }
        }

        filter[`${filterField}.sys.id[in]`] = locations.join(',');
    } else {
        // Fallback to nation ID from config..
        filter[`${filterField}.sys.id`] = config.contentful.nationId;
    }
    return filter;
}
