import { ActionContext, ActionTree } from 'vuex';
import { FilterDefinition, FilterOptionDefinition, ContentfulAPIOperator } from '~/types/FilterDefinition';

export interface ActiveFilterState {
    activeFilters: FilterDefinition[];
}
export interface ActiveFilterActions<S, R> extends ActionTree<S, R> {
    addActiveOptionToFilter(
        context: ActionContext<S, R>,
        { filter, option }: { filter: FilterDefinition; option: FilterOptionDefinition }
    ): void;
    removeActiveOptionFromFilter(
        context: ActionContext<S, R>,
        { filter, option }: { filter: FilterDefinition; option: FilterOptionDefinition }
    ): void;
    clearAllActiveFilters(context: ActionContext<S, R>): void;
}

export interface ActiveSupplierFilterActions<S, R> extends ActiveFilterActions<S, R> {
    updateActiveOptionToFilter(
        context: ActionContext<S, R>,
        { filter, option }: { filter: FilterDefinition; option: FilterOptionDefinition }
    ): void;
}

// eslint-disable-next-line no-shadow
export function findFilterIndex(state: ActiveFilterState, filter: FilterDefinition): number {
    return state.activeFilters.findIndex((activeFilter): boolean => activeFilter && activeFilter.id === filter.id);
}

// eslint-disable-next-line no-shadow
export function findOptionForFilterIndex(
    state: ActiveFilterState,
    filterIndex: number,
    option: FilterOptionDefinition
): number {
    if (state.activeFilters[filterIndex]) {
        return state.activeFilters[filterIndex].options.findIndex(
            (activeOption): boolean => activeOption.key === option.key
        );
    }
    return -1;
}

export function contentfulParamsFromFilterDefinition(filters: FilterDefinition[]): object {
    const contentfulFilters = {};
    for (const filter of filters) {
        const operator: ContentfulAPIOperator = filter.operator || '[in]';
        contentfulFilters[`fields.${filter.field}${operator}`] = filter.options
            .map((opt): string | number | boolean => opt.value)
            .join(',');
    }
    return contentfulFilters;
}

export function supplierParamsFromFilterDefinition(filters: FilterDefinition[]): object {
    const contentfulFilters = {};
    for (const filter of filters) {
        contentfulFilters[filter.name] = filter.options;
    }
    return contentfulFilters;
}
