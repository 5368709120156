import { Context, Middleware } from '@nuxt/types';
import { cookieName } from '~/store/municipality';

// Redirect home to municipality if the municipality slug is set in the request cookie.
const municipalityRedirect: Middleware = async (ctx: Context): Promise<void> => {
    const { route, redirect, app, params, error, store } = ctx;
    const slug = app.$cookies.get(cookieName);

    if (route.fullPath === '/' && slug) {
        // Do NOT change to 301! Browser will cache the first result
        // and always redirect to the same municipality in the future.
        redirect(307, `/${slug}/`);
    }

    if (params.municipality && params.municipality !== slug) {
        try {
            // Get all municipality slugs so we can compare slug param with municipality URLs
            await store.dispatch('municipality/all');

            if (!store.getters['municipality/all'][params.municipality]) {
                app.$cookies.remove(cookieName, { path: '/', maxAge: 0 });
                store.commit('municipality/CLEAR');
                error({ statusCode: 404, message: 'Pagina niet gevonden' });
            }
        } catch (message) {
            error({ statusCode: 500, message });
        }
    }
};

export default municipalityRedirect;
