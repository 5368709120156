























import { Component, Vue } from 'vue-property-decorator';
import { FooterFields } from '~/types/Footer';
import { MunicipalityFields } from '~/types/Municipality';

import DynamicIframeModal from '~/patterns/organisms/_modals/dynamic-iframe-modal/dynamic-iframe-modal.vue';
import ExternalLinkModal from '~/patterns/organisms/_modals/external-link/external-link.vue';
import GhFooter from '~/patterns/organisms/footer/footer.vue';
import Navigation from '~/patterns/organisms/navigation/navigation.vue';
import NavigationModal from '~/patterns/organisms/_modals/navigation/navigation.vue';
import OfferteModal from '~/patterns/organisms/_modals/offerte/offerte.vue';
import PostalModal from '~/patterns/organisms/_modals/postal/postal.vue';
import GhCookiebar from '~/patterns/molecules/gh-cookiebar/gh-cookiebar.vue';
import NavPushDown from '~/patterns/atoms/nav-push-down/nav-push-down.vue';

// The @Component decorator indicates the class is a Vue component
@Component({
    components: {
        DynamicIframeModal,
        ExternalLinkModal,
        GhCookiebar,
        GhFooter,
        Navigation,
        NavigationModal,
        NavPushDown,
        PostalModal,
        OfferteModal
    },

    watch: {
        aModalIsOpen(value) {
            document.body.setAttribute('data-modal-open', value);
        }
    }
})
export default class Main extends Vue {
    get aModalIsOpen(): boolean {
        return this.$store.getters['modals/openModals'].length > 0;
    }

    get municipality(): MunicipalityFields {
        // XXX: strangely enough, we need to access the fields property here..
        return this.$store.getters['municipality/fields'];
    }

    get municipalitySlug(): MunicipalityFields {
        return this.$store.getters['municipality/municipalitySlug'];
    }

    get footerObject(): FooterFields | undefined {
        return this.$store.getters['footer/footerFields'];
    }

    loadFooterFromStore() {
        return this.$store.dispatch('footer/load');
    }

    // Because fetch() and asyncData() don't work on layouts.
    serverPrefetch() {
        return this.loadFooterFromStore();
    }

    mounted() {
        if (!this.footerObject) {
            this.loadFooterFromStore();
        }
    }
}
