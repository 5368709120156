import config, { env } from '~/config/config';
export default function({ $axios, route, redirect, isStatic }): void {
    if (!isStatic && (env === 'staging' || env === 'production')) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        $axios.get(`https://${config.domain}/redirects.json`).then(({ data }) => {
            data.forEach(function(item): void {
                const res = RegExp(item.path);

                if (res.test(route.fullPath)) {
                    redirect(item.status.code, item.url);
                }
            });
        });
    }
}
