/*
 * Create icon class for tabs based on string
 * @param {String} icon
 * @return {String} modifiers
 */
export const TYPE_TOOL = 'gereedschap';
export const TYPE_CALENDAR = 'kalender';
export const TYPE_SUN = 'zon';
export const TYPE_PERSON = 'persoon';
export const TYPE_PERSONS = 'personen';

export default function(icon): string | void {
    switch (icon) {
        case TYPE_TOOL:
            return 'fa fa-wrench';
        case TYPE_CALENDAR:
            return 'fa fa-calendar';
        case TYPE_SUN:
            return 'fa fa-certificate';
        case TYPE_PERSON:
            return 'fa fa-user';
        case TYPE_PERSONS:
            return 'fa fa-users';
        default:
            return undefined;
    }
}
