import { get as getCookie } from 'js-cookie';

import ContentfulPage from '../api/contentful/page';
import ContentfulArticle from '../api/contentful/article';
import ContentfulProductSolution from '../api/contentful/product-solution';
import ContentfulExperience from '../api/contentful/experience';
import ContentfulInDeBuurtItem from '../api/contentful/indebuurt';

import { Site } from '../types/RelEnvironment';
import ContentfulTypes from '../config/ContentfulTypes';
import { SitemapRecord } from '../types/SitemapRecord';
import { Entry, EntryCollection } from '../node_modules/contentful';
import { ContentfulType } from '~/interfaces/ContentfulTypesInterface';

const target = process.env.APP_TARGET as Site;

/**
 * Get all the page-level Entries from contentful
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAllContent(site: Site = 'particulier'): Promise<EntryCollection<any>[] | never> {
    if (site === 'zakelijk') {
        return Promise.all([new ContentfulPage().getAll(site)]);
    }
    if (site === 'vve') {
        return Promise.all([
            new ContentfulPage().getAll(site),
            new ContentfulArticle().getAll(),
            new ContentfulInDeBuurtItem().getAll(null, site),
            new ContentfulProductSolution().getAll()
        ]);
    }

    return Promise.all([
        new ContentfulPage().getAll(site),
        new ContentfulArticle().getAll(),
        new ContentfulProductSolution().getAll(),
        new ContentfulInDeBuurtItem().getAll(),
        new ContentfulExperience().getAll()
    ]);
}

/**
 * Given a specific Entry, generate its slug.
 * @param entry
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateSlugForEntry(entry: Entry<any>, generate = false): string | undefined {
    function isContentfulType(item: ContentfulType | object | undefined): item is ContentfulType {
        return (item as ContentfulType).id !== undefined;
    }

    if (entry.sys.contentType && entry.sys.contentType.sys.id && entry.fields.slug) {
        for (const type of Object.values(ContentfulTypes)) {
            if (isContentfulType(type) && type.id === entry.sys.contentType.sys.id) {
                if (type.id === 'inDeBuurt') {
                    switch (entry.fields.type) {
                        case 'initiative':
                            return `${type.slug}/initiatieven/${entry.fields.slug}`;
                        case 'project':
                            return `${type.slug}/projecten/${entry.fields.slug}`;
                        case 'event':
                            return `${type.slug}/evenementen/${entry.fields.slug}`;
                    }
                }

                // Rewrite paths for sector pages
                if (!generate && entry.fields.slug.startsWith('sector-')) {
                    return `sectoren/${entry.fields.slug.replace('sector-', '')}`;
                }

                if (type.slug) {
                    return `${type.slug}/${entry.fields.slug}`;
                }
                return `${entry.fields.slug}`;
            }
        }
    }
    if (entry.fields && entry.fields.slug) {
        return `${entry.fields.slug}`;
    }
    return undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPathForEntry(entry: Entry<any>): string | undefined {
    const municipality = getCookie('rel-vuex-municipality') || '';

    if (entry.sys.contentType && entry.sys.contentType.sys.id === 'link' && entry.fields) {
        return entry.fields.url;
    }

    const path = municipality ? `${municipality}/${generateSlugForEntry(entry)}` : generateSlugForEntry(entry);

    if (path) {
        return path === '/' ? path : `/${path}`;
    }
    return undefined;
}

export function prependMunicipalityToPath(path: string) {
    const municipality = getCookie('rel-vuex-municipality') || '';
    const fullPath = municipality ? `${municipality}/${path}` : path;

    if (fullPath) {
        return fullPath === '/' ? fullPath : `/${fullPath}`;
    }
    return undefined;
}

/**
 * Return all the slugs for the applications dynamic routes.
 */
export function getDynamicRoutes(): Promise<object[] | never> {
    return getAllContent(target).then((results): object[] => {
        const slugs: object[] = [];
        results.forEach((entries): void =>
            entries.items.forEach((payload): void => {
                const slug = generateSlugForEntry(payload, true);
                if (slug) {
                    const route = (slug + '/').replace('//', '/');
                    slugs.push({ route, payload });
                }
            })
        );
        return slugs;
    });
}

/**
 * Return an array of objects containing sitemap records to use for generating sitemaps.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function getDynamicRoutesWithSitemapConfig(): Promise<SitemapRecord[] | never> {
    return getAllContent(target).then((results: EntryCollection<any>[]) => {
        const slugsWithConfig: SitemapRecord[] = [];
        results.forEach((entries: EntryCollection<any>) => {
            entries.items.forEach((entry: Entry<any>): void => {
                const slug = generateSlugForEntry(entry, true);
                if (slug) {
                    slugsWithConfig.push({ url: slug, lastmodISO: entry.sys.updatedAt });
                }
            });
        });

        return slugsWithConfig;
    });
}

/**
 * Parses URLs for:
 1. Protocol
 2. Domain
 3. Url-Path
 4. Url Fnd File Name
 5. Url End File Extension
 6. Url Parameters
 7. Hash
 */
export function parseUrl(url: string): string | null {
    const regex = /([a-z]{1,2}tps?):\/\/((?:(?!(?:\/|#|\?|&)).)+)(?:(\/(?:(?:(?:(?!(?:#|\?|&)).)+\/))?))?(?:((?:(?!(?:\.|$|\?|#)).)+))?(?:(\.(?:(?!(?:\?|$|#)).)+))?(?:(\?(?:(?!(?:$|#)).)+))?(?:(#.+))?/gi;
    const match = new RegExp(regex);

    const matches = match.exec(url);
    const domain: string | null = matches && matches[2];

    return domain;
}
