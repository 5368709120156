import { Asset } from 'contentful';
import { HasImage } from '~/interfaces/image';
import { ExperienceFields } from '~/types/Experience';
import { ProductSolutionCollection } from '~/types/Product';

/**
 * Wrap the regular (contentful) image and cloudinary image in an object for easy reference.
 * @param fields
 * @param  imageKey           key of the (contentful) image, defaults to 'image'
 * @param  cloudinaryImageKey key of the cloudinary image, defaults to 'cloudinaryimage'
 * @return                    an object with the keys 'image' ({url: the url}) and 'cloudinaryImage'
 */
export function getImage(
    fields: object,
    imageKey: string = 'image',
    cloudinaryImageKey: string = 'cloudinaryImage'
): HasImage {
    const imageObject: HasImage = {
        image: null,
        cloudinaryImage: null
    };
    if (fields && fields[imageKey]) {
        const image = <Asset>fields[imageKey];
        if (image.fields) {
            // prettier-ignore
            imageObject.image = { url: image.fields.file.url, 'resource_type': 'image' };
        }
    }
    if (fields && fields[cloudinaryImageKey]) {
        imageObject.cloudinaryImage = fields[cloudinaryImageKey][0];
    }
    return imageObject;
}

/**
 * Fall-back to hero image of appliedMSolutions if this entry has none.
 */
export function getImageWithSolutionFallback(
    entryFields: ExperienceFields | undefined,
    appliedSolutions: ProductSolutionCollection | undefined
): HasImage | string | undefined {
    if (entryFields) {
        if (entryFields.image && entryFields.image.length) {
            return entryFields.image[0];
        } else if (appliedSolutions) {
            for (const measure of Object.values(appliedSolutions)) {
                if (measure.fields.cloudinaryImage.length) {
                    return measure.fields.cloudinaryImage[0];
                }
            }
        }
    }
    return undefined;
}
